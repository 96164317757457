@use "sass:math";

.HeaderMenu_hovered {
  .HeaderMenu-Item.HeaderMenu-Item_subItems {
    &:hover {
      .HeaderMenu-SubList {
        z-index: 2;
        visibility: visible;
        opacity: 1;
        transition: all 0.3s;
      }
    }
  }
}

.HeaderMenu {
  &_hovered {
    .HeaderMenu-Link {
      opacity: 0.5;
    }
  }

  &-List {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  &-Item {
    margin-right: 24px;
    padding: 28px 0;

    @media (min-width: 1340px) {
      margin-right: 36px;
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      .HeaderMenu-Link {
        opacity: 1;
      }
    }
  }

  &-Link {
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }

  &-SubList {
    position: absolute;
    top: 100%;
    left: 0;
    display: flex;
    visibility: hidden;
    width: 100%;
    background-color: #fff;
    opacity: 0;
    flex-flow: wrap;

    &-Inner {
      z-index: 1;
      width: 100%;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: -10000px;
      left: -10000px;
      bottom: 0;
      background-color: #fff;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: var(--color-gainsboro);
    }
  }

  &-Container {
    max-width: 1900px;
    padding-left: 228px;
    margin: 0 auto;
  }

  &-Extended {
    max-width: 1900px;
    display: flex;
  }

  &-Extended-Col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 0 0 16.666666%;
    padding: 20px 12px 24px 12px;

    &:first-child {
      padding-left: 28px;
    }

    &:not(:first-child):not(:last-child) {
      border-left: 1px solid var(--color-platinum);
    }

    &:last-child {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 1px;
        left: 0;
        right: 0;
        height: calc(100% - 1px);
        background-color: var(--color-cultured);
      }
    }
  }

  &-Preview {
    width: 70px;
    margin-bottom: 10px;
    margin-left: 12px;

    &,
    picture {
      display: flex;
    }

    picture,
    img {
      width: 100%;
    }

    img {
      height: auto;
    }
  }

  &-LinkList {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
    position: relative;
    z-index: 1;
  }

  &-SubItemList {
    padding: 0;
  }

  &-SubItem {
    margin-right: var(--space-m);

    &:last-child {
      margin-right: 0;
    }

    &_column {
      width: percentage(math.div(3, 12));
      margin-right: 0;
    }

    .Button {
      justify-content: flex-start;
      align-items: center;
      height: auto;
      min-height: var(--button-height);
      padding: var(--space-s) var(--space-m);
      font-size: 16px;
      font-weight: 500;
      line-height: 1;
      white-space: normal;
      letter-spacing: 0.2px;
      text-transform: none;
      color: var(--color-reach-black);

      .Button-Label {
        text-align: left;
      }

      &.Button_view_ghost {
        --button-height: var(--control-height-s);
        height: var(--button-height);
        line-height: var(--button-height);

        .Picture {
          display: flex;
          align-items: center;
          width: 24px;
          height: 24px;
          margin-right: var(--space-m);
        }
      }
    }
  }

  &-Overlay {
    position: fixed;
    z-index: 111;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
}

.HeaderMenu-Columns {
  display: flex;
  width: 100%;

  &-Primary {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;

    .Title {
      margin: 0 0 var(--space-m) var(--space-m);
    }
  }

  &-Secondary {
    display: flex;
    flex-direction: column;
    flex: 0 0 20%;
    background-color: var(--color-decor);
    padding: 28px 0 var(--space-m);
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: var(--color-gainsboro);
    }

    .Title {
      padding: 0 var(--space-xl);
      margin-bottom: 20px;
    }
  }
}

.HeaderMenu-Column {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex: 0 0 25%;
  padding: var(--space-xl) var(--space-xl) var(--space-xl) 0;

  &.Wide {
    flex: 0 0 100%;
    flex-direction: row;
    grid-gap: 12px;
    flex-wrap: wrap;
    grid-gap: 12px;
  }
}

.HeaderMenu-SubLink {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: var(--space-xs) 10px !important;
  min-height: var(--space-3xl);
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: var(--color-charcoal);
  text-transform: none;
  letter-spacing: 0;
  transition: background-color 0.2s ease-in;
  border-radius: 2px;
  min-height: unset;
  height: unset;
  padding-top: 10px !important;
  padding-bottom: 10px !important;

  .Button-Label {
    overflow: hidden;
    text-overflow: unset;
    white-space: break-spaces;
    text-align: left;
  }

  &_category,
  &_all {
    font-weight: bold;
  }

  &_all {
    color: var(--color-web);
  }

  &_labs {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    margin-top: auto;
    margin-left: 12px;
    background-color: var(--color-decor);

    .Picture {
      &,
      picture {
        display: flex;
        align-items: flex-start;
      }

      img {
        height: 20px;
        width: auto;
      }
    }
  }

  &.active,
  &:hover,
  &.Active {
    background-color: #e4e9f2;
    color: var(--color-charcoal);
  }
}

.HeaderMenu-SubLink-Label {
  display: inline-flex;
  padding: 2px;
  transform: translate(-5deg);
  border-radius: 1px;
  background-color: #fff;
  background-color: var(--color-orange);
  margin-left: var(--space-xs);

  &_Accent {
    background-color: #53cff8;
  }
}

.HeaderMenu-Partner {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  padding: var(--space-xs) 12px !important;
  min-height: var(--space-3xl);
  font-size: 16px;
  line-height: 1;
  font-weight: 400;
  color: var(--color-charcoal);
  text-transform: none;
  letter-spacing: 0;
  transition: background-color 0.2s ease-in;
  border-radius: 2px;
  min-height: 40px;
  height: unset;

  .Button-Label {
    overflow: hidden;
    text-overflow: unset;
    white-space: break-spaces;
    text-align: left;
  }

  &.active,
  &:hover,
  &.Active {
    background-color: #e4e9f2;
    color: var(--color-charcoal);
  }

  .Picture {
    flex: 0 0 24px;
    margin-right: var(--space-s);

    &,
    picture {
      display: flex;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  .Icon {
    flex: 0 0 16px;
    margin-left: var(--space-xs);
  }
}
